/**
 * This file was automatically generated by Strapi.
 * Any modifications made will be discarded.
 */
import ckeditor5 from "/node_modules/.strapi/vite/deps/@_sh_strapi-plugin-ckeditor_strapi-admin.js?v=f3b82cd3";
import strapiCloud from "/node_modules/.strapi/vite/deps/@strapi_plugin-cloud_strapi-admin.js?v=f3b82cd3";
import usersPermissions from "/node_modules/.strapi/vite/deps/@strapi_plugin-users-permissions_strapi-admin.js?v=f3b82cd3";
import { renderAdmin } from "/node_modules/.strapi/vite/deps/@strapi_strapi_admin.js?v=f3b82cd3";

renderAdmin(document.getElementById("strapi"), {
  plugins: {
    ckeditor5: ckeditor5,
    "strapi-cloud": strapiCloud,
    "users-permissions": usersPermissions,
  },
});
